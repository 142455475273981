<template>
  <div class="row child-component">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
      <div class="d-block row col-lg-8 mx-auto">
        <div class="fieldborder">
          <OmniSelect class="omniipclass" v-model="ClientDetails.HfmCodeValue" :options="Clients" name="HFM CLIENT"
            placeholder="Enter or Select Master Client Code, Name, or HFM Code"
            toolTipText="<span>For corporate reporting, each client requires an HFM client Code. If this is a new business win for OMG, you will likely have to request a new HFM Code. If you are unsure, please reach out to your Agency Finance Director.</span>"
            :allowFiltering="true" :showSearch="true" multiple="true" />

          <p v-show="!HFMForm" class="Select-a-billing-par d-flex justify-content-center">
            Select a client to display the new client form
          </p>
          <p class="Dont-see-the-what-y">
            <span> Don’t see the what you’re looking for? </span> <span
              style="color:#00a1d2 !important;font-weight: 600;cursor:pointer;" @click="showAddProductModal()">Request a
              new HFM client</span>
          </p>
        </div>

        <b-popover triggers="hover" placement="bottom" :show.sync="ShowAddExceptionsPopover" display="block"
          target="add-exceptions" variant="primary"></b-popover>

        <div v-show="HFMForm" class="fieldborder">
          <div class="fieldborder">
            <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Entity" name="Entity"
              :showLabel="true" label="value" v-model="ClientDetails.Entity" :options="OMDEntity"></OmniSelect>
          </div>

          <div class="">
            <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Agency" name="AGENCY"
              toolTipText="<span>The Agency Selected will dictate the Logo printed on all Fee and Production Invoices billed out of AX.</span>"
              :showLabel="true" label="Select Agency" v-model="ClientDetails.Agency" :options="AgencyList"></OmniSelect>
          </div>

          <div class="row">
            <div class="mx-auto col-lg-12">
              <div v-bind:class="{
                  ClientRectangle: showtruncationvalidationmessage,
                  defaultClientRectangle: !showtruncationvalidationmessage,
                }">
                <div class="fieldborder">
                  <label for="clientname" class="lbltxt">CLIENT NAME</label>
                  <!-- <span
                    v-show="showtruncationvalidationmessage"
                    class="truncatedvalidationmdg"
                    >too many characters - please use suggested truncated field
                  </span> -->
                  <b-form-input autocomplete="off" id="cilentname" class="omniipclass" @keyup="remaincharCount()"
                    v-model="ClientDetails.ClientName" :maxlength="20" placeholder="Enter Client Name"></b-form-input>
                </div>

                <div v-show="showtruncated" class="fieldborder">
                  <div>
                    <span class="SUGGESTED-CLIENT-NAM">
                      SUGGESTED CLIENT NAME
                    </span>
                    <br>
                    <span class="Due-to-character-res">
                      Due to character restrictions, we suggest the using the following client name:
                    </span>
                  </div>
                  <label style="margin-left: 9px !important" for="truncatedclientname" class="lbltxt">TRUNCATED CLIENT
                    NAME</label>
                  <b-form-input autocomplete="off" v-on:blur="handleBlur" id="truncatedcilentname" class="omniipclass"
                    :maxlength="20" v-model="ClientDetails.TruncatedClientName" placeholder="Enter Truncated Client Name"
                    style="margin-left: 12px !important"></b-form-input>
                </div>
              </div>
            </div>
          </div>

          <div class="fieldborder">
            <!-- <label for="requestclientcode" class="lbltxt" toolTipText=""
              >REQUESTED CLIENT CODE</label> -->
            <slot name="label" class="lbltxt">
              <span class="lbltxt" style="margin-left: 11px">
                REQUESTED CLIENT CODE
              </span>
              <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                v-b-tooltip.hover.v-dark.right.html="' If your optional client code is already in use, the financial systems team will select the next logical client code. 3 digit client code preferred. Final client code opened will be displayed on confirmation notification email (once request is fulfilled).'
                  ">
                <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path
                    d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                    id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                </g>
              </svg>
            </slot>
            <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :minlength="2" :maxlength="3"
              v-model="ClientDetails.RequestClientcode" placeholder="Enter Requested Client Code"></b-form-input>
          </div>
          <div class="fieldborder">
            <label>Is this for a client code that is already on file that needs to be opened in an additional media?</label>
            <b-form-group style="margin-left: 12px !important; margin-bottom: 0px">
              <b-form-radio-group class="omniipclass" id="isExistingClient" v-model="ClientDetails.IsExistingClient" name="isExistingClient">
                <b-form-radio value="true">Yes</b-form-radio>
                <b-form-radio value="false">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="fieldborder" style="padding-top: 22px">
            <OmniSelect class="omniipclass" v-model="ClientDetails.BpmAssigned" :options="BPMUsers" name="BPM Assigned"
              placeholder="Enter or Select Budget Assigned Managers" :showSearch="true" />
          </div>

          <br />
          <div class="fieldborder" style="margin-top: -17px !important">
            <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select DDS Accounting Office Code" :disabled="!ClientDetails.Entity"
              name="DDS ACCOUNTING OFFICE CODE" :showLabel="true" label="DDS ACCOUNTING OFFICE CODE"
              v-model="ClientDetails.DdsAccountingofcecode" :options="DDSAccountingCodesFiltered"></OmniSelect>
          </div>

          <div style="padding-top: 10px">
            <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select DDS Media Office Code"
              name="DDS MEDIA OFFICE CODE" :showLabel="true" :disabled="!ClientDetails.DdsAccountingofcecode" label="DDS MEDIA OFFICE CODE"
              v-model="ClientDetails.DDSMediaOfcecode" :options="DDSMediaCodes"
              toolTipText="<span>The Media office selected will dictate the Logo that prints on the Media Invoices billed out of DDS/MediaOcean.</span>">
            </OmniSelect>
          </div>

          <div class="fieldborder" style="margin-top: -4px">
            <label for="axlocatoin" class="lbltxt">AX LOCATION/COMPANY</label>
            <b-form-input autocomplete="off" id="axcompanylocation" class="omniipclass"
              v-model="ClientDetails.AXLocationCompany" placeholder="AX Location/Company"
              style="cursor: default !important" readonly></b-form-input>
          </div>
          <br />
          <span class="udefheading">UDEF</span>
          <span class="udefoptionalheading">Optional</span>
          <hr class="UDEFhr" />
          <div class="UDFMainDiv">
            <div class="UDF-item">
              <b-form-checkbox v-model="ClientDetails.CheckProductUDF1" value="true" inline>
              </b-form-checkbox>
              <b-form-group>
                <label>

                  PRODUCT UDEF 1 <span v-if="ProdUdef1validationtextbox" style="padding-left: 185px;"
                    class="truncatedvalidationmdg"> Please provide maximum 20 charecters. </span>
                </label>

                <b-form-input max-length="20" autocomplete="off" id="productudef1" class="UDEFomniipclass"
                  placeholder="Enter Product UDEF 1" v-model="ClientDetails.ProductUDF1" v-bind:class="{
                      errortext: ProdUdef1validationtextbox,
                      noerrortext: !ProdUdef1validationtextbox,
                    }"></b-form-input>
              </b-form-group>
            </div>

            <div class="UDF-item">
              <b-form-checkbox v-model="ClientDetails.CheckProductUDF2" value="true" inline>
              </b-form-checkbox>
              <b-form-group>
                <label>
                  PRODUCT UDEF 2 <span v-if="ProdUdef2validationtextbox" style="padding-left: 185px;"
                    class="truncatedvalidationmdg"> Please provide maximum 20 charecters. </span>
                </label>
                <b-form-input max-length="20" autocomplete="off" id="productudef2" class="UDEFomniipclass"
                  placeholder="Enter Product UDEF 2" v-model="ClientDetails.ProductUDF2" v-bind:class="{
                      errortext: ProdUdef2validationtextbox,
                      noerrortext: !ProdUdef2validationtextbox,
                    }" ref="email"></b-form-input>
              </b-form-group>
            </div>

            <div class="UDF-item">
              <!-- <input type="checkbox" style="margin-left:-20px;" value="true"  :checked="ClientDetails.CheckEstimateUDF1" v-model="ClientDetails.EstUDEF1" />    -->
              <b-form-checkbox v-model="ClientDetails.CheckEstimateUDF1" value="true" inline>
              </b-form-checkbox>
              <b-form-group>
                <label>
                  ESTIMATE UDEF 1 <span v-if="EstUdef1validationtextbox" style="padding-left: 185px;"
                    class="truncatedvalidationmdg"> Please provide maximum 20 charecters. </span>
                </label>
                <b-form-input max-length="20" autocomplete="off" id="estimateudef1" class="UDEFomniipclass"
                  placeholder="Enter Estimate UDEF 1" v-bind:class="{
                      errortext: EstUdef1validationtextbox,
                      noerrortext: !EstUdef1validationtextbox,
                    }" v-model="ClientDetails.EstimateUDF1"></b-form-input>
              </b-form-group>
            </div>
            <div class="UDF-item">
              <!-- <input type="checkbox" style="margin-left:-20px;"  value="true" :checked="ClientDetails.CheckEstimateUDF2" v-model="ClientDetails.EstUDEF2" />    -->
              <b-form-checkbox v-model="ClientDetails.CheckEstimateUDF2" value="true" inline>
              </b-form-checkbox>
              <b-form-group>
                <label>
                  ESTIMATE UDEF 2 <span v-if="EstUdef2validationtextbox" style="padding-left: 185px;"
                    class="truncatedvalidationmdg"> Please provide maximum 20 charecters. </span>
                </label>
                <b-form-input max-length="20" autocomplete="off" id="estimateudef2" class="UDEFomniipclass"
                  placeholder="Enter Estimate UDEF 2" v-bind:class="{
                      errortext: EstUdef2validationtextbox,
                      noerrortext: !EstUdef2validationtextbox,
                    }" v-model="ClientDetails.EstimateUDF2"></b-form-input>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="form-group pt-60 text-right footer-buttons">
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>
      <button v-if="IsDraftVisible" class="text-uppercase btn btn-secondary btn-primary" @click="SaveasDraft()">
        SAVE FOR LATER
      </button>
      <button class="text-uppercase btn custom-button btn-primary" :disabled="!ShowAddProduct && this.$v.$invalid"
        @click="AddClient()">
        NEXT
      </button>

    </div>
    <b-modal no-enforce-focus size="xl" v-model="ShowAddProduct" class="Title-Text" hide-footer
      title="Request New HFM Client">
      <p class="You-cannot-submit-th">
        You cannot submit the new client form before the HFM Client is approved
        however, you may save the form and submit later.
      </p>
      <div class="d-block row">
        <div class="Rectangle col-lg-8 mx-auto fieldborder">
          <div class="col-md-12 rectangleborder">
            <b-form-group
              label="Is the expected revenue for this client greater than $50,000, or is the A/R expected to be greater than $250,000?"
              class="timeonly-text">
              <b-form-radio-group id="expected-revenue" name="expected-revenue">
                <b-form-radio v-model="ClientInfo.HeyperienClient" value="eulargradeyes">Yes</b-form-radio>
                <b-form-radio v-model="ClientInfo.HeyperienClient" value="eulargradeno">No</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
        <br />
        <div class="fieldborder col-lg-8 mx-auto" style="margin-top: 10px !important"
          v-show="ClientInfo.HeyperienClient == 'eulargradeno'">
          <OmniSelect class="pt-0 omniippopupclass omniindexclass" placeholder="Select Other Hyperion Client"
            name="OTHER HYPERION CLIENT" :showLabel="true" :showSearch="true" label="OTHER HYPERION CLIENT"
            v-model="ClientInfo.OtherHyperienClient" :options="EntityList"></OmniSelect>
        </div>
      </div>
      <br />
      <div class="text-right">
        <button class="btn btn-link text-uppercase" @click="CancelAdd()">
          Cancel
        </button>
        <button v-show="ClientInfo.HeyperienClient == 'eulargradeno'" :disabled="this.ShowAddProduct && this.$v.$invalid"
          class="text-uppercase btn custom-button btn-primary" @click="CancelHFMPopup()">
          Submit
        </button>

        <a href="https://onewp.okta.com/" target="_blank" style="padding-top: 8px !important"
          v-show="ClientInfo.HeyperienClient == 'eulargradeyes'" v-bind:class="{
              'text-uppercase btn disabledbtn  disabled':
                this.ShowAddProduct && this.$v.$invalid,
              'text-uppercase btn custom-button  btn-primary':
                !this.ShowAddProduct && !this.$v.$invalid,
            }" class="text-uppercase btn custom-button btn-primary" role="button" aria-disabled="true">Request</a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import store from "@/store";
import { required, requiredIf, maxLength, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";
//const service=()=>import("../../services/Dropdowns");
import service from "../../services/Dropdowns";

export default {
  validations: {
    ClientDetails: {
      ClientName: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      TruncatedClientName: {
        required: requiredIf(function () {
          return this.showtruncated == true;
        }),
      },
      RequestClientcode: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
        maxLength: maxLength(3),
        minLength: minLength(2),
      },
      AXLocationCompany: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      Entity: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      Agency: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      DDSMediaOfcecode: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      DdsAccountingofcecode: {
        required: requiredIf(function () {
          return !this.ShowAddProduct;
        }),
      },
      ProductUDF1: {
        required: requiredIf(function () {
          return this.ClientDetails.CheckProductUDF1;
        }),
        maxLength: maxLength(20),
      },

      ProductUDF2: {
        required: requiredIf(function () {
          return this.ClientDetails.CheckProductUDF2;
        }),
        maxLength: maxLength(20),
      },

      EstimateUDF1: {
        required: requiredIf(function () {
          return this.ClientDetails.CheckEstimateUDF1;
        }),
        maxLength: maxLength(20),
      },

      EstimateUDF2: {
        required: requiredIf(function () {
          return this.ClientDetails.CheckEstimateUDF2;
        }),
        maxLength: maxLength(20),
      },
      IsExistingClient: {
        required,
      },
    },
    ClientInfo: {
      OtherHyperienClient: {
        required: requiredIf(function (model) {
          return this.ShowAddProduct && model.HeyperienClient == "eulargradeno";
        }),
      },
    },
  },

  data() {
    return {
      ProdUdef1validationtextbox: false,
      ProdUdef2validationtextbox: false,
      EstUdef1validationtextbox: false,
      EstUdef2validationtextbox: false,
      ShowAddExceptionsPopover: false,
      TooltipInfoIcon: require("@/assets/Images/infoicon.svg"),
      UDEFCheck: false,
      showtruncated: false,
      showtruncationvalidationmessage: false,
      truncatedmaxlength: 20,
      HFMClientsCode: [],
      Clients: [],
      ClientCode: null,
      Status: null,
      ClientName: null,
      ClientMDMID: null,
      AgencyFound: false,
      ProductsFromServer: [],
      ClientProductList: [],
      ClientMediaList: [],
      ClientProductMediaList: [],
      BPMUsers: [],
      OMDEntity: [],
      AgencyList: [],
      billingparties: [],
      EditLoad: {
        EntityLoaded: false,
        AgencyLoaded: false,
        DDSAccountingOfficeCodesLoaded: false,
        DDSMediaCodesLoaded: false,
        HFMLoaded: false
      },
      // ClientDetails:{
      //    ProductUDF1:null
      // },
      ClientInfo: {
        HfmClient: null,
        HeyperienClient: null,
        OtherHyperienClient: null,
        HfmCodeValue: [],
      },
      DDSAccountingCodes: [],
      DDSMediaCodes: [],
      DDSAccountingCodesFiltered: [],
      EntityList: [
        { label: "5_G9OTHOMD – OMD – OTHER CLIENTS - OMD", value: "5_G9OTHOMD – OMD – OTHER CLIENTS - OMD" },
        { label: "5_G9OTHPHD – PHD – OTHER CLIENTS - PHD", value: "5_G9OTHPHD – PHD – OTHER CLIENTS - PHD" },
        { label: "5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE", value: "5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE" },
        {
          label: "5_G9OTHE – OTHER – OTHER CLIENTS",
          value: "5_G9OTHE – OTHER – OTHER CLIENTS",
        },
      ],

      ShowAddProduct: false,
      ShowBillingException: false,
      ShowCommissionException: false,
    };
  },
  mounted() {
    if (!this.CanAccessList) {
      this.$router.push({
        name: "unauthorized",
        params: { message: "UnAuthorized" },
      });
    } else if (!this.CanCreateClientRequest) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    } else {
      this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
      if (!this.id) {
        this.InitiateObject();
      }
      //this.LoadUIDatas();
      this.LoadBPMUsers();
      this.LoadHFMClientCode();
      this.LoadClients();
      this.LoadDropdownValues();
    }
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false
    }
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
    // window.console.log(`draft type is ${this.isDraft}`);
  },
  created() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  watch: {
    "ClientDetails.ProductUDF1": function (val) {
      if (val.length > 0) {
        this.ClientDetails.CheckProductUDF1 = true;
      } else {
        this.ClientDetails.CheckProductUDF1 = false;
      }

      if (val.length > 20) {
        this.ProdUdef1validationtextbox = true;
      }

      else {
        this.ProdUdef1validationtextbox = false;
      }
    },

    "ClientDetails.ProductUDF2": function (val) {
      if (val.length > 0) {
        this.ClientDetails.CheckProductUDF2 = true;
      } else {
        this.ClientDetails.CheckProductUDF2 = false;
      }
      if (val.length > 20) {
        this.ProdUdef2validationtextbox = true;
      }

      else {
        this.ProdUdef2validationtextbox = false;
      }
    },

    "ClientDetails.EstimateUDF1": function (val) {
      if (val.length > 0) {
        this.ClientDetails.CheckEstimateUDF1 = true;
      } else {
        this.ClientDetails.CheckEstimateUDF1 = false;
      }

      if (val.length > 20) {
        this.EstUdef1validationtextbox = true;
      }

      else {
        this.EstUdef1validationtextbox = false;
      }
    },

    "ClientDetails.EstimateUDF2": function (val) {
      if (val.length > 0) {
        this.ClientDetails.CheckEstimateUDF2 = true;
      } else {
        this.ClientDetails.CheckEstimateUDF2 = false;
      }

      if (val.length > 20) {
        this.EstUdef2validationtextbox = true;
      }
      else {
        this.EstUdef2validationtextbox = false;
      }
    },

    "ClientDetails.CheckProductUDF1": function (val) {
      if (val == false) {
        this.ClientDetails.ProductUDF1 = "";
      }
    },

    "ClientDetails.CheckProductUDF2": function (val) {
      if (val == false) {
        this.ClientDetails.ProductUDF2 = "";
      }
    },

    "ClientDetails.CheckEstimateUDF1": function (val) {
      if (val == false) {
        this.ClientDetails.EstimateUDF1 = "";
      }
    },

    "ClientDetails.CheckEstimateUDF2": function (val) {
      window.console.log(`Value check est2 ${val}`);
      if (val == false) {
        this.$set(this.ClientDetails, "EstimateUDF2", "");
      }
    },

    "ClientDetails.Agency": function (val) {
      let Agency = this.AgencyList.findIndex((item) => {
        return item.value == val;
      });
      if (Agency > -1) {
        this.ClientDetails.AgencyName = this.AgencyList[Agency].label;
      } else {
        this.ClientDetails.AgencyName = "";
      }
    },
    "ClientDetails.Entity": function (val,newVal) {
      if(val && newVal != null) {
        this.Billing.AORClientCode = "";
        this.Billing.AORClientName = "";
        this.Billing.AORClientAgency = "";
      }
      let entity = this.OMDEntity.findIndex((item) => {
        return item.value == val;
      });
      if (entity > -1) {
        this.ClientDetails.EntityName = this.OMDEntity[entity].label;
      } else {
        this.ClientDetails.EntityName = "";
      }
      this.EntityChange();
      this.LoadBillingPartyDdl();
    },
    "ClientCode": function (newVal) {
      this.AssignClientName();
      if (newVal) {
        this.LoadClients();
        if (!this.IsEdit) {
          this.ResetTable();
        }
      }
    },
    "ClientDetails.HfmCodeValue": function (val) {
      // let index = this.HFMClientsCode.findIndex((item) => {
      //   return item.value == val;
      // });
      this.ClientDetails.HFMCodeName = val;
      if (this.Edit && !this.IsHFMLoaded && this.IsHFMCustomEntitySelected()) {
        this.LoadClients();

      }
    },
    "ClientDetails.DdsAccountingofcecode": function () {
       if (!this.Edit || this.IsDDSMediaCodesLoaded) {
        this.DDSAccountingofficeChange();
       }
    },
  },

  computed: {
    ...mapGetters("client", {
      ClientDetails: "ClientBasicDetails",
      SubmittedFormData: "SubmittedFormData",
      clientinfopagetwo: "ClientEularInfo",
      Billing: "ClientBillingInformations",
    }),
    IsDraftVisible: function () {
      return this.id == null || this.id == 0 || this.isDraft == true;
    },
    IsAgencyLoaded() {
      return this.EditLoad && this.EditLoad.AgencyLoaded;
    },
    IsHFMLoaded() {
      return this.EditLoad && this.EditLoad.HFMLoaded;
    },
    IsEntityLoaded() {
      return this.EditLoad && this.EditLoad.EntityLoaded;
    },
    IsDDSAccountingOfficeCodesLoaded() {
      return this.EditLoad && this.EditLoad.DDSAccountingOfficeCodesLoaded;
    },
    IsDDSMediaCodesLoaded() {
      return this.EditLoad && this.EditLoad.DDSMediaCodesLoaded;
    },
    Edit() {
      return this.id && parseInt(this.id) > 0;
    },
    HFMForm() {
      return this.ClientDetails &&
        this.ClientDetails.HfmCodeValue &&
        this.ClientDetails.HfmCodeValue.length > 0
        ? true
        : false;
    },
    CanCreateClientRequest() {
      return this.$store.getters.canCreateClient;
    },
    CanAccessList() {
      return this.$store.getters.canAccessList;
    },
  },
  methods: {
    AssignAgencyName() {
      let Agency = this.AgencyList.findIndex((item) => {
        return item.value == this.ClientDetails.Agency;
      });
      if (Agency > -1) {
        this.ClientDetails.AgencyName = this.AgencyList[Agency].label;
      } else {
        this.ClientDetails.AgencyName = "";
      }
    },
    AssignClientName() {
      this.ClientName = null;
      this.ClientMDMID = null;

      if (this.ClientCode != null && this.ClientCode.length > 0) {
        let clientIndex = this.Clients.findIndex((val) => {
          return val.value == this.ClientCode;
        });
        if (clientIndex > -1) {
          let clientObj = this.Clients[clientIndex];
          this.ClientName = clientObj.ClientName;
          this.ClientMDMID = clientObj.ClientMDMID;
        }
      }
    },
    AddCustomHFMEntityToDropdown() {
      this.HFMClientsCode.push({ label: this.ClientDetails.HfmCodeValue, value: this.ClientDetails.HfmCodeValue });
      if (this.HFMClientsCode && this.HFMClientsCode.length > 0) {
        this.HFMClientsCode = this.HFMClientsCode.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        let indexUSA = this.HFMClientsCode.findIndex(
          (i) => i.label == this.ClientDetails.HfmCodeValue
        );
        if (indexUSA > -1)
          this.HFMClientsCode.unshift(
            this.HFMClientsCode.splice(indexUSA, 1)[0]
          );
      }

    },
    IsHFMCustomEntitySelected() {
      return this.ClientDetails.HfmCodeValue != null && this.ClientDetails.HfmCodeValue.indexOf('~') < 0;
    },
    LoadAgencySelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(this.ClientDetails, "Agency", this.SubmittedFormData.Agency);
        this.$set(this.EditLoad, "AgencyLoaded", true);
        this.AssignAgencyName();
      }
    },
    LoadHFMSelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.AddCustomHFMEntityToDropdown();
        this.$set(this.EditLoad, "HFMLoaded", true);
      }
    },
    LoadEntitySelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(this.ClientDetails, "Entity", this.SubmittedFormData.Entity);
        this.$set(this.EditLoad, "EntityLoaded", true);
        if (this.ClientDetails.Entity == this.SubmittedFormData.Entity) {
          this.EntityChange();
        }
      }
    },
    LoadDDSAccoutingOfficeCodesSelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(
          this.ClientDetails,
          "DdsAccountingofcecode",
          this.SubmittedFormData.DDSAccountingOfficeCode
        );
        this.$set(this.EditLoad, "DDSAccountingOfficeCodesLoaded", true);
        if (
          this.ClientDetails.DdsAccountingofcecode ==
          this.SubmittedFormData.DDSAccountingOfficeCode
        ) {
          this.DDSAccountingofficeChange();
        }
      }
    },
    LoadDDSMediaOfficeCodesSelectedValue() {
      if (this.Edit && this.SubmittedFormData != null) {
        this.$set(
          this.ClientDetails,
          "DDSMediaOfcecode",
          this.SubmittedFormData.DDSMediaOfficeCode
        );
        this.$set(this.EditLoad, "DDSMediaCodesLoaded", true);
      }
    },


    handleBlur() {
      this.showtruncationvalidationmessage = false;
    },

    LoadBillingPartyDdl() {
      var inputObj = {
        entityCode: this.ClientDetails.EntityName.split("-")[0].trim()
      };
      this.$store.dispatch("client/GetBillingParty", inputObj)
        .then((res) => {
          res.data.push({
            label: "Time Only (Client Related Time MED)",
            value: "Time Only (Client Related Time MED)",
            AXLocation: null,
          });
          res.data.push({
            label: "Time Only (Internal Project Related Time ADM)",
            value: "Time Only (Internal Project Related Time ADM)",
            AXLocation: null,
          });
          res.data.push({
            label: "Direct",
            value: "Direct",
            AXLocation: null,
          });

          if (res.data && res.data.length > 0) {
            res.data = res.data.sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            );

            let indexDirect = res.data.findIndex((i) => i.label == "Direct");
            if (indexDirect > -1)
              res.data.unshift(res.data.splice(indexDirect, 1)[0]);

            let indexTimeOnlyMED = res.data.findIndex((i) => i.label == "Time Only (Client Related Time MED)");
            if (indexTimeOnlyMED > -1)
              res.data.unshift(res.data.splice(indexTimeOnlyMED, 1)[0]);

            let indexTimeOnlyADM = res.data.findIndex((i) => i.label == "Time Only (Internal Project Related Time ADM)");
            if (indexTimeOnlyADM > -1)
              res.data.unshift(res.data.splice(indexTimeOnlyADM, 1)[0]);

          }

          this.billingparties = [];
          this.billingparties = res.data;
          store.commit("client/loadBillingparty", this.billingparties);
        })
        .catch((err) => {
          alert(err);
        });
    },

    EntityChange() {
      var entityid = this.ClientDetails.Entity;
      service
        .getAgencyDropdownValues(entityid, entityid)
        .then((res) => {
          this.AgencyList = res.data;
          if (this.Edit && !this.IsAgencyLoaded) {
            this.LoadAgencySelectedValue();
          } else {
            if (res.data.length == 1) {
              this.ClientDetails.Agency = res.data[0].value;
            } else {
              this.ClientDetails.Agency = null;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });

      var inputObj = {
        entityCode: this.ClientDetails.EntityName.split("-")[0].trim(),
        searchAccoffceKey: "",
      };
      this.$store.dispatch("client/GetAccountingOfficeCodes", inputObj)
        .then((res) => {
          this.DDSAccountingCodes = res.data;
          this.DDSAccountingCodesFiltered = this.DDSAccountingCodes.filter(function (el) {
            return el.value !== "" && el.value !== undefined;
          });

          if (this.Edit && !this.IsDDSAccountingOfficeCodesLoaded) {
            this.LoadDDSAccoutingOfficeCodesSelectedValue();
          } else {
            this.ClientDetails.DdsAccountingofcecode = null;
            this.ClientDetails.AXLocationCompany = null;
            this.ClientDetails.DDSMediaOfcecode = null;
            if (res.data.length > 0) {
              if (res.data.length == 1) {
                this.ClientDetails.DdsAccountingofcecode = res.data[0].value;
              }
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    DDSAccountingofficeChange() {
      var entityid = this.ClientDetails.EntityName.split("-")[0].trim();
      var accountingofficecode = this.ClientDetails.DdsAccountingofcecode;
      if (accountingofficecode != null) {
        var inputObj = {
          entityCode: entityid,
          searchAccoffceKey: accountingofficecode,
        };
        this.$store.dispatch("client/GetMediaOfficeCodes", inputObj)
        .then((res) => {
          let uniqueDDSMediaCodes = [];
          res.data.map(x => uniqueDDSMediaCodes.filter(a => a.value == x.value).length > 0 ? null : uniqueDDSMediaCodes.push(x));
          this.DDSMediaCodes = uniqueDDSMediaCodes;
          let axLocationFiltered = res.data.filter(function(el) {
            return el.AXLocation !== "" && el.AXLocation !== undefined && el.AXLocation !== null;
          });
          this.ClientDetails.AXLocationCompany= axLocationFiltered[0].AXLocation;
          if (this.Edit && !this.IsDDSMediaCodesLoaded) {
            this.LoadDDSMediaOfficeCodesSelectedValue();
          } else {
            this.ClientDetails.DDSMediaOfcecode = null;
            if (uniqueDDSMediaCodes.length == 1) {
              this.ClientDetails.DDSMediaOfcecode = uniqueDDSMediaCodes[0].value;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
      }
    },

    remaincharCount: function () {
      if (this.ClientDetails.ClientName.length > this.truncatedmaxlength) {
        this.showtruncated = true;
        this.showtruncationvalidationmessage = true;
        this.ClientDetails.TruncatedClientName = this.ClientDetails.ClientName.slice(
          0,
          20
        );
      } else {
        this.showtruncated = false;
        this.showtruncationvalidationmessage = false;
        this.ClientDetails.TruncatedClientName = "";
      }
    },

    InitiateObject() { },
    showAddProductModal() {
      this.ClientInfo.HeyperienClient = null;
      this.ClientInfo.OtherHyperienClient = null;
      this.ShowAddProduct = true;
    },
    AddProduct() { },
    CancelAdd() {
      this.ClientInfo.HeyperienClient = null;
      this.ClientInfo.OtherHyperienClient = null;
      this.ShowAddProduct = false;
    },
    Cancel() {
        this.$router.push("/ClientProduct");
    },
    AddBillingException() {
      this.ShowBillingException = true;
    },
    AddCommissionException() {
      this.ShowCommissionException = true;
    },



    SaveasDraft() {
      this.$store
        .dispatch("client/SaveClientDraft", this.isDraft == true ? parseInt(this.id) : null)
        .then((res) => {
          if (res.status == 200) {
            store.commit("progress/removeProcessing", {
              root: true
            });
            store.dispatch(
              "progress/setSuccessMessage",
              `The Client request have been saved as Draft successfully`
            );

            this.$router.push("/ClientProduct");
          }
          else {
            window.console.log('error while uploading files');
          }

        })
        .catch((ex) => {
          this.$store.dispatch(
            "progress/setErrorMessage",
            `Error while Saving Client as Draft. ${ex}`
          );
        });
    },
    AddClient() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      store.commit("client/NewClientInfo", this.ClientDetails);
      if (this.id) {
        this.$router.push({
          name: "Euler Grade",
          params: {
            id: this.id,
            isDraft: this.isDraft
          },

        });
      } else {
        this.$router.push({ name: "Euler Grade" });
      }
      this.InitiateObject();
      2;
    },

    CancelHFMPopup() {
      // 5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE
      // 5_G9OTHOMD – OMD – OTHER CLIENTS - OMD
      // 5_G9OTHE – OTHER – OTHER CLIENTS
      // 5_G9OTHPHD – PHD – OTHER CLIENTS - PHD
      if (this.ClientInfo.OtherHyperienClient == "5_G9OTHOMD – OMD – OTHER CLIENTS - OMD") {
        var Omdindex = this.HFMClientsCode.findIndex(
          (x) => x.value == "5_G9OTHOMD – OMD – OTHER CLIENTS - OMD"
        );
        if (Omdindex === -1) {
          this.HFMClientsCode.push(this.EntityList[0]);
        }
        if (this.HFMClientsCode && this.HFMClientsCode.length > 0) {
          this.HFMClientsCode = this.HFMClientsCode.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          let indexUSA = this.HFMClientsCode.findIndex(
            (i) => i.label == "5_G9OTHOMD – OMD – OTHER CLIENTS - OMD"
          );
          if (indexUSA > -1)
            this.HFMClientsCode.unshift(
              this.HFMClientsCode.splice(indexUSA, 1)[0]
            );
        }
        this.ClientDetails.Entity = this.HFMClientsCode[0].value;
        this.ClientDetails.HFMCodeName = this.HFMClientsCode[0].value;
        this.ClientDetails.HfmCodeValue = this.HFMClientsCode[0].value;
      } else if (this.ClientInfo.OtherHyperienClient == "5_G9OTHPHD – PHD – OTHER CLIENTS - PHD") {
        var Phdindex = this.HFMClientsCode.findIndex(
          (x) => x.value == "5_G9OTHPHD – PHD – OTHER CLIENTS - PHD"
        );
        if (Phdindex === -1) {
          this.HFMClientsCode.push(this.EntityList[1]);
        }
        if (this.HFMClientsCode && this.HFMClientsCode.length > 0) {
          this.HFMClientsCode = this.HFMClientsCode.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          let indexUSA = this.HFMClientsCode.findIndex(
            (i) => i.label == "5_G9OTHPHD – PHD – OTHER CLIENTS - PHD"
          );
          if (indexUSA > -1)
            this.HFMClientsCode.unshift(
              this.HFMClientsCode.splice(indexUSA, 1)[0]
            );
        }
        this.ClientDetails.Entity = this.HFMClientsCode[0].value;
        this.ClientDetails.HFMCodeName = this.HFMClientsCode[0].value;
        this.ClientDetails.HfmCodeValue = this.HFMClientsCode[0].value;
      } else if (this.ClientInfo.OtherHyperienClient == "5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE") {
        var OtherClientindex = this.HFMClientsCode.findIndex(
          (x) => x.value == "5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE"
        );
        if (OtherClientindex === -1) {
          this.HFMClientsCode.push(this.EntityList[2]);
        }
        if (this.HFMClientsCode && this.HFMClientsCode.length > 0) {
          this.HFMClientsCode = this.HFMClientsCode.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          let indexUSA = this.HFMClientsCode.findIndex(
            (i) => i.label == "5_G9OTHHAS – HEART – OTHER CLIENTS - HEARTS AND SCIENCE"
          );
          if (indexUSA > -1)
            this.HFMClientsCode.unshift(
              this.HFMClientsCode.splice(indexUSA, 1)[0]
            );
        }
        this.ClientDetails.Entity = this.HFMClientsCode[0].value;
        this.ClientDetails.HFMCodeName = this.HFMClientsCode[0].value;
        this.ClientDetails.HfmCodeValue = this.HFMClientsCode[0].value;
      } else if (
        this.ClientInfo.OtherHyperienClient == "5_G9OTHE – OTHER – OTHER CLIENTS"
      ) {
        var Hasindex = this.HFMClientsCode.findIndex(
          (x) => x.value == "5_G9OTHE – OTHER – OTHER CLIENTS"
        );
        if (Hasindex === -1) {
          this.HFMClientsCode.push(this.EntityList[3]);
        }
        if (this.HFMClientsCode && this.HFMClientsCode.length > 0) {
          this.HFMClientsCode = this.HFMClientsCode.sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          let indexUSA = this.HFMClientsCode.findIndex(
            (i) => i.label == "5_G9OTHE – OTHER – OTHER CLIENTS"
          );
          if (indexUSA > -1)
            this.HFMClientsCode.unshift(
              this.HFMClientsCode.splice(indexUSA, 1)[0]
            );
        }
        this.ClientDetails.Entity = this.HFMClientsCode[0].value;
        this.ClientDetails.HFMCodeName = this.HFMClientsCode[0].value;
        this.ClientDetails.HfmCodeValue = this.HFMClientsCode[0].value;
      }
      this.ShowAddProduct = false;
      this.ClientInfo.HeyperienClient = null;
      this.ClientInfo.OtherHyperienClient = null;
    },

    async LoadClients() {
      this.$store
        .dispatch("client/GetMasterClient")
        .then((response) => {
          this.Clients = response.data;
        })
        .catch((ex) => {
          window.console.log(`Error is ${ex}`);
        });
    },
    async LoadUIDatas() {
      service
        .getUIDatas("test")
        .then((res) => {
          this.HFMClientsCode = res.data["HFM Child Clients"];
          this.BPMUsers = res.data["BPMUser"];
          this.OMDEntity = res.data["Financial Entity"];
        })
        .catch((err) => {
          alert(JSON.stringify("Service error" + err));
        });
    },

    async LoadBPMUsers() {
      let currentenv;
      if (window.location.href.indexOf("finsysreq-stage") > -1) {
        currentenv = "Development";
      }
      else if (window.location.href.indexOf("finsysreq-uat") > -1) {
        currentenv = "Development";
      }
      else if (window.location.href.indexOf("localhost") > -1) {
        currentenv = "Development";
      }
      else {
        currentenv = "Production";
      }
      service
        .getBPMUsers(currentenv,"Client")
        .then((res) => {
          this.BPMUsers = res.data;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async LoadDropdownValues() {
      service
        .getOriginalEntityRecords("Financial Entity")
        .then((res) => {
          this.OMDEntity = res.data;
          if (this.Edit && !this.IsEntityLoaded) {
            this.LoadEntitySelectedValue();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    async LoadHFMClientCode() {
      service
        .getOriginalEntityRecords("HFM Child Clients")
        .then((res) => {
          let removeDuplicates = res.data.reduce((acc, current) => {
            const x = acc.find(item => item.value === current.value);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          this.HFMClientsCode = removeDuplicates;
          if (this.id && this.IsHFMCustomEntitySelected() && !this.IsHFMLoaded) {
            this.LoadHFMSelectedValue();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
};
</script>
<style scoped>
.SUGGESTED-CLIENT-NAM {
  width: 179px;
  height: 19px;
  margin-left: 17px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #77a2bb !important;
}

.Due-to-character-res {
  width: 431px;
  height: 15px;
  margin-left: 15px !important;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #4d5161 !important;
}

.errortext {
  border: 1px solid #df5678 !important;
}

.noerrortext {
  border: #e9eef0 !important;
}


.udeflbl {
  padding-bottom: 10px;
  padding-left: 13px;
}

.udefheading {
  width: 37px;
  height: 8px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  color: #77a2bb;
}

.UDF-item {
  display: flex !important;
  margin-left: -26px !important;
}

.udefoptionalheading {
  width: 37px;
  height: 8px;
  margin-left: 344px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: right;
  color: #a2a9ad;
}

.UDEFhr {
  margin-top: 5px !important;
}

.UDFMainDiv {
  margin-top: 40px !important;
}

.my-tooltip-class {
  color: yellow !important;
}

.UDF-item .custom-control.custom-control-inline.custom-checkbox {
  margin-right: .25rem !important;
  z-index: 0 !important;
}

.UDF-item>fieldset.form-group {
  margin-top: -34px !important;
}

.btn-text:hover {
  color: yellow !important;
}

div>.UDF-item:not(:first-child) {
  margin-top: 2rem !important;
}

.child-component>div:first-child {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.disabledbtn {
  padding-top: 9px !important;
  background: lightgray !important;
  color: white !important;
  border: 1px gray !important;
}

.truncatedvalidationmdg {
  text-transform: none !important;
  margin-left: -77px !important;
  font-size: 10px !important;
  color: #df5678 !important;
  font-family: "Open Sans" !important;
  font-size: 11px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: italic !important;
  line-height: 0.8 !important;
  letter-spacing: normal !important;
  text-align: right !important;
}

.redtextbox {
  width: 415px !important;
  height: 35px !important;
  border: 1px solid #df5678 !important;
  background: center right 7px no-repeat url("../../assets/Icons/error-icon.svg") #fff !important;
}

.floating-label-input {
  margin-top: 0px !important;
}

.fieldborder {
  padding-bottom: 15px !important;
}

.rectangleborder {
  padding-top: 20px !important;
  /* padding-bottom:20px !important; */
}

.rectangleborder .form-group {
  margin-bottom: 0 !important;
}

.omniipclass {
  height: 35px !important;
  border: #e9eef0 !important;
  /* style="width:415px; height:35px;" */
}

.UDEFomniipclass {
  width: 415px !important;
  height: 35px !important;

  /* style="width:415px; height:35px;" */
}

.omniippopupclass {
  width: 346px !important;
  height: 35px !important;
  /* border:#E9EEF0 !important; */
  /* style="width:415px; height:35px;" */
}

::-webkit-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder,
::placeholder {
  width: 206px;
  height: 15px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: #a2a9ad;
}

.Rectangle {
  border-radius: 5px;
  background-color: rgba(216, 216, 216, 0.2);
}

.ClientRectangle {
  border-radius: 5px !important;
  background-color: rgba(216, 216, 216, 0.2) !important;
  width: 460px !important;
  margin-left: -2% !important;
}

.defaultClientRectangle {
  border-radius: 5px !important;
}

.v-select {
  background-color: white !important;
}

.REQUEST-NEW-INTERAGE {
  width: 214px;
  height: 9px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
  margin-left: 50px !important;
}

.Select-a-billing-par {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.71;
  letter-spacing: normal;
  color: #a2a9ad;
}

.row.vertical-line:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 25%;
  bottom: 25%;
  left: 50%;
  border-left: 1px solid rgba(151, 151, 151, 0.19);
  transform: translate(-50%);
}

.CREATE-A-NEW-CLIENT {
  width: 142px;
  height: 19px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.57px;
  color: #2c3865;
}

.lbltxt {
  width: 200px;
  height: 8px;
  font-family: "Open Sans";
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #4d5161;
}

.verticlaline {
  width: 740px;
  height: 0px;
  border: solid 1px #e2e2e2;
  margin-left: 106px;
}

.nxtbtn {
  background-color: lightgray !important;
  border-radius: 19px !important;
  color: white !important;
}

.optionalclass {
  font-size: 13px !important;
  padding-left: 325px !important;
}

.Call-to-Action {
  width: 46px;
  height: 9px;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}

.optionalcss {
  width: 37px;
  height: 8px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: right;
  color: #a2a9ad;
  padding-left: 158px !important;
}

.Title-Text {
  width: 498px;
  height: 29px;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
}

.You-cannot-submit-th {
  height: 34px;
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a9ad;
}

.Additional-Text {
  width: 471px;
  height: 40px;
  font-family: "Open Sans" !important;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #4d5161;
}

.Dont-see-the-what-y {
  width: 434px;
  height: 10px;
  margin: 25px 136px 18px 3px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: #a2a9ad;
}
</style>